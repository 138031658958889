import { memo } from "react";
import styles from "./index.module.css";
import CrownIcon from "./CrownIcon";
import classNames from "classnames";
import ClockIcon from "./ClockIcon";
import DiamondIcon from "./DiamondIcon";

interface ISubscriptionInformationRowPropTypes {
  color?: "primary" | "danger" | "transparent" | "blue";
  iconColor: "primary" | "danger" | "blue";
  primaryText?: string;
  secondaryText?: string;
  button?: JSX.Element;
  icon?: "clock" | "crown" | "diamond";
}

const ICON_MAP = {
  clock: ClockIcon,
  crown: CrownIcon,
  diamond: DiamondIcon,
};

const SubscriptionInformationRow = ({
  color = "primary",
  iconColor = "primary",
  primaryText,
  secondaryText,
  button,
  icon = "crown",
}: ISubscriptionInformationRowPropTypes) => {
  const Icon = ICON_MAP[icon];

  return (
    <div
      className={classNames([
        styles["subscription-info-row"],
        styles["subscription-info-row-" + color],
      ])}
    >
      <div className={styles["content-wrapper"]}>
        <div
          className={classNames([
            styles["icon-wrapper"],
            styles[`icon-wrapper-${iconColor}`],
          ])}
        >
          <Icon />
        </div>
        <div className={styles["texts-wrapper"]}>
          <div
            className={classNames([
              styles["primary-text"],
              styles["primary-text-" + color],
            ])}
          >
            {primaryText}
          </div>
          <div className={styles["secondary-text"]}>{secondaryText}</div>
        </div>
      </div>
      {!!button && <div className={styles["button-wrapper"]}>{button}</div>}
    </div>
  );
};

export default memo(SubscriptionInformationRow);
