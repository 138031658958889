import React, { ReactNode, useEffect, useRef, useState } from "react";
import { IGlobalStore } from "../../../stores/GlobalStore";
import { RouteComponentProps, withRouter } from "react-router";
import { REDIRECT_URL_KEY } from "../../../utils/windowUtils";
import { AUTH_ROUTES } from "../../routes";
import AuthService from "../../../services/AuthService";
import useCurrentUser from "../hooks/useCurrentUser";
import { UserService } from "../../../services/UserService";

interface IAuthGuardPropTypes extends RouteComponentProps {
  children: ReactNode;
  GlobalStore?: IGlobalStore;
}

let tokenCheckInterval = null;

const AuthGuard = ({ children, history, location }: IAuthGuardPropTypes) => {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const messagesSubscription = useRef(null);
  const currentUser = useCurrentUser();

  const moveToLogin = () => {
    history.push({
      pathname: AUTH_ROUTES.login,
      search: `?${REDIRECT_URL_KEY}=${location.pathname}`,
    });
  };

  const loadProfile = async () => {
    if (!AuthService.hasToken()) return moveToLogin();
    if (loading || loaded) return;

    setLoading(true);
    let user =
      AuthService.getStoredUser() ||
      (await UserService.getLoggedUser().then((res) => res.data));
    currentUser.setUser(user);
    setLoading(false);
    setLoaded(true);

    if (!user) moveToLogin();
  };

  const checkToken = () => {
    if (tokenCheckInterval) {
      clearTimeout(tokenCheckInterval);
    }

    if (!AuthService.hasToken()) {
      AuthService.signOut();
      currentUser.setUser(null);
      moveToLogin();
    }

    tokenCheckInterval = setTimeout(() => {
      checkToken();
    }, 1000);
  };

  const initialize = () => {
    loadProfile();
    checkToken();

    return () => {
      if (tokenCheckInterval) {
        clearTimeout(tokenCheckInterval);
      }

      if (messagesSubscription.current) {
        messagesSubscription.current.unsubscribe();
      }
    };
  };

  useEffect(initialize, [initialize]);

  useEffect(() => {}, [currentUser.user]);

  return <>{!!currentUser.user && !!currentUser.user && children}</>;
};

export default withRouter(AuthGuard);
