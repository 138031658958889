import { useTranslation } from "react-i18next";
import useSubscription from "../../../../account/hooks/useSubscription";
import { useSubscriptionPaymentDialog } from "../../../payments/useSubscriptionPaymentDialog";
import useCurrentUser from "../../../../account/hooks/useCurrentUser";
import SubscriptionExpirationCounter from "../../../../shared/components/SubscriptionExpirationCounter";

// Display the banner for 7 days after the user has created their account
const dayToDisplay = 7;

const PromoBanner = () => {
  const { t } = useTranslation();
  const { user, isOwner } = useCurrentUser();
  const subscription = useSubscription();
  const subscriptionDialog = useSubscriptionPaymentDialog();
  const pricingDialog = subscriptionDialog.content;

  if (subscription.active) {
    return null;
  }

  const daysInMilliseconds = dayToDisplay * 24 * 60 * 60 * 1000;
  const expirationDate = new Date(new Date().getTime() + daysInMilliseconds);
  const showNewUserPromo =
    isOwner &&
    user.createdAt &&
    new Date().getTime() - new Date(user.createdAt).getTime() <
      daysInMilliseconds;

  if (showNewUserPromo) {
    return (
      <>
        {pricingDialog}
        <SubscriptionExpirationCounter
          buttonProps={{
            children: t("subscription.subscribeNowBtn"),
            onClick: subscriptionDialog.openDialog,
          }}
          expirationDate={expirationDate}
          labelSlot={
            <div className="text-sm">
              Get 15% off your annual subscription or 15% off for your first two
              months with code <b>"ST15OFFX2"</b>
              <br />
              {/* <span className="text-xs"> */}
              Limited time offer - valid for 7 days after registration
              {/* </span> */}
            </div>
          }
        />
      </>
    );
  }

  return null;
};

export default PromoBanner;
