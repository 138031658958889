import { useContext, useEffect } from "react";
import { UserMetadataContext } from "../context/user-metadata.context";
import { UserMetadataService } from "../../../services/UserMetadataService";
import { UserMetadata } from "../../../models/UserMetadata";

const useUserMetadata = () => {
  const ctx = useContext(UserMetadataContext);
  if (!ctx) {
    throw new Error(
      "useUserMetadata must be used within a UserMetadataContextProvider"
    );
  }

  useEffect(() => {
    const metadata = ctx.metadata;

    if (!metadata) {
      UserMetadataService.getMetadata().then((res) => {
        ctx.setMetadata(res.data.metadata);
      });
    }
  }, []);

  const { metadata, setMetadata } = ctx;

  const getProperty = (key: keyof UserMetadata) => {
    return metadata?.[key] || null;
  };

  const setProperty = (key: keyof UserMetadata, value: boolean) => {
    setMetadata({ ...metadata, [key]: value });
    UserMetadataService.updateUserMetadata({
      [key]: value,
    });
  };

  return {
    metadata,
    loaded: !!metadata,
    setMetadata,
    getProperty,
    setProperty,
  };
};

export default useUserMetadata;
