import React from "react";
import ModelListElement, { unlockUIType } from "./ModelListElement";
import { getModelPageUrl } from "../../../../../../utils/globalUtils";
import { ModelPageViewTypes } from "../../../../../../const/global";
import { inject, observer } from "mobx-react";
import { IGlobalStore } from "../../../../../../stores/GlobalStore";
import { IModelListActions, IModelListItem } from "../interface";
import { PermissionService } from "../../../../../../services/PermissionService";
import { ServiceType } from "../../../../../../models/ServicePlan";
import { ModelAccessPolicy } from "../../../../ModelAccessPolicy";
import { showUnlockUI } from "./utils";
import ModelBadge from "./components/ModelBadge/ModelBadge";
import useCurrentUser from "../../../../../account/hooks/useCurrentUser";

interface IOwnedModelListElementPropTypes extends IModelListActions {
  GlobalStore?: IGlobalStore;
  model: IModelListItem;
  onUnlock: () => void;
  onShare: (model: IModelListItem) => void;
  onHide: (model: IModelListItem) => void;
  onReveal: (model: IModelListItem) => void;
  onDelete: (model: IModelListItem) => void;
}

const OwnedModelListElement = (props: IOwnedModelListElementPropTypes) => {
  const { model, onUnlock, onDelete } = props;
  const { user } = useCurrentUser();
  const showTacbrowse = PermissionService.hasService(
    user,
    ServiceType.Tacbrowse
  );

  const accessLocked = ModelAccessPolicy.isLocked(
    model.status,
    model.accessType
  );
  const accessType = ModelAccessPolicy.modelPermissionsType(
    user,
    model.accessType,
    model.accessExpiresAt
  );
  const canDelete = ModelAccessPolicy.canDelete(
    user,
    user.email === model.owner.email
  );

  return (
    <ModelListElement
      accessType={accessType}
      accessLocked={accessLocked}
      showUnlockUI={showUnlockUI(model.status, accessType)}
      showExpiresAt={showUnlockUI(model.status, accessType)}
      unlockUIProps={{
        type: unlockUIType(accessType),
        onClick: onUnlock,
      }}
      badge={<ModelBadge status={model.status} accessType={accessType} />}
      allowToShowUploadButton
      showOptions={!accessLocked || canDelete}
      showShare={!accessLocked}
      showHide={false}
      showReveal={model.archived && !accessLocked}
      showTacbrowse={showTacbrowse && !accessLocked}
      showDelete={canDelete}
      disableDelete={ModelAccessPolicy.isDeleteDisabled(model)}
      onDelete={onDelete}
      mapRedirectUrl={getModelPageUrl(model.id, ModelPageViewTypes.Map)}
      modelRedirectUrl={getModelPageUrl(model.id, ModelPageViewTypes.Model)}
      {...props}
    />
  );
};

export default inject("GlobalStore")(observer(OwnedModelListElement));
