import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import AuthService from "./AuthService";
import { HEADER_AUTHORIZATION_KEY, JWT_STORAGE_NAME } from "const/global";
import { ITranslationFields } from "locale/dictionary";

/**
 * Interceptors
 */
const requestInterceptor = (request: AxiosRequestConfig): AxiosRequestConfig =>
  request;
const responseSuccessInterceptor = (response: AxiosResponse): AxiosResponse =>
  response;
const responseErrorInterceptor = (err: AxiosError): void => {
  throw err;
};

axios.interceptors.request.use(requestInterceptor as any);
axios.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor
);

export interface IArrayResponse {
  items: Array<any>;
  total: number;
}

export interface IResponse<T = any> {
  data: T;
  status: number;
  error?: string;
  total?: number;
  rawData?: any;
  items: any[];
  headers?: any;
}

export interface IErrorResponse extends IResponse {
  localizationKey: ITranslationFields;
}

interface IHttpOptions {
  headers?: Record<string, string>;
  data?: any;
  withJWTToken?: boolean;
  params?: Record<string, any>;
  responseType?: any;
  onUploadProgress?: any;
  getHeaders?: boolean;
  cache?: boolean;
}

export const stringifyData = (data: object): string => JSON.stringify(data);

const defaultRequestOptions = {
  headers: {
    "Content-Type": "application/json",
  },
  withJWTToken: true,
};

const prepareParams = (params: Record<string, any>): any => {
  const preparedParams: Record<string, any> = {};

  Object.keys(params).forEach((key: string) => {
    if (params[key] !== "") {
      preparedParams[key] = params[key];
    }
  });

  return preparedParams;
};

const addAuthorizationToHeader = (
  headers: Record<string, string> = {}
): Record<string, string> => {
  const authorizationToken = AuthService.getAuthorizationToken();

  return {
    ...headers,
    [HEADER_AUTHORIZATION_KEY]: authorizationToken,
  };
};

const prepareRequestOptions = (
  options: IHttpOptions,
  data: any
): AxiosRequestConfig => {
  const allOptions = { ...defaultRequestOptions, ...options };
  const { withJWTToken, ...rest } = allOptions;
  let params = {};

  if (options.params) {
    params = prepareParams(options.params);
  }

  return {
    ...rest,
    params,
    headers: {
      ...defaultRequestOptions.headers,
      ...(withJWTToken
        ? addAuthorizationToHeader(options.headers)
        : options.headers),
    },
    data,
  };
};

const parseResponse =
  (
    resolve: (value?: IResponse | PromiseLike<IResponse> | undefined) => void,
    options: IHttpOptions
  ) =>
  (response: AxiosResponse): void => {
    const { status, headers } = response;
    const { data } = response;
    let newData = data;
    let items = [];

    if (data && data.items) {
      newData = [...data.items];
      items = data.items;
    }

    const serializedResponse: IResponse = {
      data: newData,
      status,
      total: data && data.total ? data.total : 0,
      rawData: data,
      items,
      headers: response.headers,
    };

    if (options.getHeaders) {
      serializedResponse.headers = headers;
    }

    resolve(serializedResponse);
  };

const isUnauthorized = (resData: any): boolean => {
  if (
    resData?.statusCode === 401 &&
    (resData?.message === "error_be_user_unauthorized" ||
      resData?.message === "error_be_auth_token_not_provided" ||
      resData?.message === "error_be_user_unauthorized" ||
      resData?.message === "could_not_authorize")
  ) {
    return true;
  }
  return false;
};

const parseErrorResponse =
  (
    resolve: (
      value?: IErrorResponse | PromiseLike<IErrorResponse> | undefined
    ) => void
  ) =>
  (axiosErr: AxiosError): void => {
    const { response, stack } = axiosErr;
    const resData = response?.data as any;

    if (isUnauthorized(resData)) {
      localStorage.removeItem(JWT_STORAGE_NAME);
    }

    resolve({
      data: response ? resData : {},
      status: response ? response.status : 0,
      rawData: response,
      error: stack,
      items: [],
      localizationKey: response ? resData.localizationKey : "",
    });
  };

const asyncAxiosMiddleware = (
  method: AxiosRequestConfig["method"],
  url: string,
  data: any,
  options: IHttpOptions
): Promise<IResponse> => {
  const requestOptions = prepareRequestOptions(options, data);

  return new Promise((resolve, rej): void => {
    axios
      .request({ method, url, ...requestOptions })
      .then(parseResponse(resolve, options))
      .catch(parseErrorResponse(rej));
  });
};

const getXHRMethod =
  (method: AxiosRequestConfig["method"]) =>
  (
    url: string,
    data: any = {},
    options: IHttpOptions = defaultRequestOptions
  ): Promise<IResponse> =>
    asyncAxiosMiddleware(method, url, data, options);

const METHODS = {
  get: getXHRMethod("GET"),
  post: getXHRMethod("POST"),
  put: getXHRMethod("PUT"),
  patch: getXHRMethod("PATCH"),
  delete: getXHRMethod("DELETE"),
};

export default METHODS;
