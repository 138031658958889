import { inject, observer } from "mobx-react";
import { Model } from "models/Model";
import React, { useEffect, useState } from "react";
import ModelService from "views/models/services/ModelService";
import { IGlobalStore } from "../../../stores/GlobalStore";
import ModelChooseModal from "../components/ModelChooseModal";
import { IModelListItem } from "../pages/ModelListPage.tsx/ModelList/interface";
import useCurrentUser from "../../account/hooks/useCurrentUser";

interface IModelChooseModalContainerPropTypes {
  GlobalStore?: IGlobalStore;
  onChange: (model: IModelListItem) => void;
  onClose: () => void;
  open: boolean;
  selectedModel: IModelListItem;
}

let searchDebounceTimer = null;

const ModelChooseModalContainer = ({
  GlobalStore,
  selectedModel,
  open,
  onChange,
  onClose,
}: IModelChooseModalContainerPropTypes) => {
  const { user } = useCurrentUser();
  const [currentModel, setCurrentModel] = useState<IModelListItem | null>(null);
  const [modelSearchValue, setModelSearchValue] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [models, setModels] = useState<IModelListItem[]>([]);

  const handleSearchValueChange = (newValue: string) => {
    setModelSearchValue(newValue);

    if (searchDebounceTimer) {
      clearTimeout(searchDebounceTimer);
      searchDebounceTimer = null;
    }

    searchDebounceTimer = setTimeout(() => {
      getModels(newValue);
    }, 1000);
  };

  const handleModelSelect = (model: IModelListItem) => {
    setCurrentModel(model);
  };

  const handleModelModalClose = () => {
    setModalOpen(false);
    onClose();
  };

  const handleModelChoose = () => {
    setModalOpen(false);
    onChange(currentModel);
  };

  const getModels = (name = "") => {
    const qp = {
      archived: false,
      tags: [],
      name,
      organizationId: user?.organization?.id,
    };

    ModelService.getModels(0, qp).then((res) => {
      setModels(res.data.models);
    });
  };

  useEffect(() => {
    setModalOpen(open);

    if (open) {
      getModels();
    }
  }, [open]);

  useEffect(() => {
    setCurrentModel(selectedModel);
  }, [selectedModel]);

  useEffect(() => {}, []);

  return (
    <ModelChooseModal
      open={modalOpen}
      models={models}
      currentModel={currentModel}
      inputValue={modelSearchValue}
      onInputChange={handleSearchValueChange}
      onSelect={handleModelSelect}
      onClose={handleModelModalClose}
      onChoose={handleModelChoose}
    />
  );
};

export default inject("GlobalStore")(observer(ModelChooseModalContainer));
