import useCurrentUser from "../../../../account/hooks/useCurrentUser";
import useUserMetadata from "../../../../account/hooks/useUserMetadata";
import { SubscriptionType } from "../../../../payments/payments.model";
import Button from "../../../../shared/buttons/Button";
import AdvancedInformationRow from "../../../../shared/components/AdvancedInformationRow";

const DeleteModelInfoBanner = () => {
  const { user } = useCurrentUser();
  const { getProperty, setProperty, loaded } = useUserMetadata();

  if (
    !user ||
    user?.organization?.cjis ||
    user?.organization?.subscription?.type?.value === SubscriptionType.Premium
  )
    return null;

  const isBannerDismissed = getProperty("deleteModelInfoBannerDismissed");
  if (!loaded || isBannerDismissed) return null;

  return (
    <AdvancedInformationRow
      color="danger"
      icon="clock"
      iconColor="danger"
      primaryText="New Model Cleanup Strategy"
      secondaryText="For Free and Lite subscriptions, Failed and Unfinished models will be automatically deleted after 30 days to improve platform performance. No action needed."
      button={
        <Button
          onClick={() => {
            setProperty("deleteModelInfoBannerDismissed", true);
          }}
        >
          Dismiss
        </Button>
      }
    />
  );
};

export default DeleteModelInfoBanner;
