import { createContext, useState } from "react";
import { UserMetadata } from "../../../models/UserMetadata";

interface IUserMetadataContext {
  metadata: UserMetadata | null;
  setMetadata: (metadata: UserMetadata) => void;
}

export const UserMetadataContext = createContext<IUserMetadataContext | null>(
  null
);

export const UserMetadataContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // On this level we can ensure token exists - should be used after auth guard
  const [metadata, setMetadata] = useState<UserMetadata | null>(null);

  return (
    <UserMetadataContext.Provider value={{ metadata, setMetadata }}>
      {children}
    </UserMetadataContext.Provider>
  );
};
