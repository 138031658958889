import React from 'react'
import styled from 'styled-components';
import { IAppContext } from "../../interfaces";
import { useTranslation } from 'react-i18next';
import SimpleInformationRow from "../../shared/components/SimpleInformationRow/InformationRow";
import { WeatherService } from "services/WeatherService";
import { IPublicModel } from "models/Model";
import Alert from "../../shared/components/Alert";

interface IModelWeatherSectionPropTypes {
  model: IPublicModel;
}

const InformationSection = styled.div`
  padding-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;

const ModelWeatherSection = ({ model }: IModelWeatherSectionPropTypes) => {
  const { t } = useTranslation();

  return (
    <div className="model-weather-section">
      {model.weather ? (
        <InformationSection>
          <SimpleInformationRow
            title={t("description")}
            description={`${model.weather.main}, ${model.weather.description}`}
          />
          <SimpleInformationRow
            title={t("temperature")}
            description={
              <span>
                {WeatherService.convertCelsiusToFahrenheit(
                  model.weather.temperature
                ).toFixed(1)}{" "}
                &deg;F
              </span>
            }
          />
          <SimpleInformationRow
            title={t("temperatureFeelsLike")}
            description={
              <span>
                {WeatherService.convertCelsiusToFahrenheit(
                  model.weather.feelsLikeTemperature
                ).toFixed(1)}{" "}
                &deg;F
              </span>
            }
          />
          <SimpleInformationRow
            title={t("clouds")}
            description={`${model.weather.clouds} %`}
          />
          <SimpleInformationRow
            title={t("pressure")}
            description={`${model.weather.pressure} hPa`}
          />
          <SimpleInformationRow
            title={t("humidity")}
            description={`${model.weather.humidity} %`}
          />
          <SimpleInformationRow
            title={t("windSpeed")}
            description={`${WeatherService.convertMetersPerSecondToMilesPerHour(
              model.weather.windSpeed
            )} Mph`}
          />
          <SimpleInformationRow
            title={t("windDegree")}
            description={<span>{model.weather.windDegree} &deg;</span>}
          />
          {!!model.weather.rain && (
            <SimpleInformationRow
              title={t("rain")}
              description={`${WeatherService.convertMmToInches(
                model.weather.rain
              )} In/h`}
            />
          )}
          {!!model.weather.snow && (
            <SimpleInformationRow
              title={t("snow")}
              description={`${WeatherService.convertMmToInches(
                model.weather.snow
              )} In/h`}
            />
          )}
        </InformationSection>
      ) : (
        <Alert type="warning">{t("weatherDetailsNotAvailable")}</Alert>
      )}
    </div>
  );
};

export default ModelWeatherSection;
