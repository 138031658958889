import { useState, useEffect, ReactNode } from "react";
import Button, { IButtonPropTypes } from "../../buttons/Button";

interface IProps {
  buttonProps: IButtonPropTypes;
  expirationDate: Date;
  labelSlot: ReactNode;
  closeButtonProps?: {
    onClick: () => void;
  };
}

const SubscriptionExpirationCounter = ({
  buttonProps,
  expirationDate,
  labelSlot,
  closeButtonProps,
}: IProps) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    // Set initial expiration time (30 days from now)
    const expirationTime = expirationDate;

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = expirationTime.getTime() - now;

      // Calculate time units
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });

      // Clear interval when expired
      if (distance < 0) {
        clearInterval(timer);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    // Cleanup
    return () => clearInterval(timer);
  }, []);

  const timeLessThanOneDay = timeLeft.days === 0;

  return (
    <div className="bg-purple-50 px-4 py-2 flex items-center justify-between rounded-lg text-center">
      <div className="flex flex-col gap-y-4 lg:flex-row items-center justify-center gap-x-8 w-full">
        <div className="flex items-center space-x-2">{labelSlot}</div>
        <div className="counter-wrapper">
          <div className="flex items-end space-x-1">
            {!timeLessThanOneDay && (
              <>
                <div className="flex flex-col justify-center items-center">
                  <div className="text-xs capitalize">days</div>
                  <div className="bg-white rounded px-2 py-1 text-lg font-bold h-10 flex items-center min-w-10">
                    {String(timeLeft.days).padStart(2, "0")}
                  </div>
                </div>
                <div className="h-10 flex flex items-center text-xl font-bold">
                  :
                </div>
              </>
            )}

            {/* Hours */}
            <div className="flex flex-col justify-center items-center">
              <div className="text-xs capitalize">hours</div>
              <div className="bg-white rounded px-2 py-1 text-lg font-bold h-10 flex items-center min-w-10">
                {String(timeLeft.hours).padStart(2, "0")}
              </div>
            </div>

            <div className="h-8 text-xl flex items-center font-bold">:</div>
            {/* Minutes */}
            <div className="flex flex-col justify-center items-center">
              <div className="text-xs capitalize">min</div>
              <div className="bg-white rounded px-2 py-1 text-lg font-bold h-10 flex items-center min-w-10">
                {String(timeLeft.minutes).padStart(2, "0")}
              </div>
            </div>

            {
              <>
                <div className="h-10 flex flex items-center text-xl font-bold">
                  :
                </div>
                {/* Seconds */}
                <div className="flex flex-col justify-center items-center">
                  <div className="text-xs capitalize">sec</div>
                  <div className="bg-white rounded px-2 py-1 text-lg font-bold h-10 flex items-center min-w-10">
                    {String(timeLeft.seconds).padStart(2, "0")}
                  </div>
                </div>
              </>
            }
          </div>
        </div>
        {buttonProps && <Button size="small" {...buttonProps} />}
      </div>

      <div className="flex items-center justify-between">
        {closeButtonProps && (
          <button className="text-gray-400 hover:text-gray-600 transition-colors">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default SubscriptionExpirationCounter;
