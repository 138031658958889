import React from "react";
import MoreIcon from "views/shared/Icons/MoreIcon";
import SkyeDropdown, {
  DropdownWrapper,
} from "views/shared/components/Dropdown/Dropdown";
import DropdownItem from "views/shared/components/Dropdown/DropdownItem";
import ShareIcon from "views/shared/Icons/ShareIcon";
import PrivateIcon from "views/shared/Icons/PrivateIcon";
import TacbrowseIcon from "views/shared/Icons/TacbrowseIcon";
import { IAppContext } from "views/interfaces";
import styled from "styled-components";
import Box from "views/shared/components/Box";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { SESSION_ROUTES } from "views/routes";
import { useTranslation } from "react-i18next";
import { IModelListElementOptionsProps, IModelListItem } from "../../interface";
import TrashIcon from "../../../../../../shared/Icons/TrashIcon";
import SkyeTooltip from "../../../../../../shared/components/SkyeTooltip";

export interface IModelListElementOptionsPropTypes
  extends RouteComponentProps,
    IModelListElementOptionsProps {
  onShare: (model: IModelListItem) => void;
  onHide: (model: IModelListItem) => void;
  onReveal: (model: IModelListItem) => void;
  onDelete: (model: IModelListItem) => void;
}

const OptionsIconButton = styled(Box)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  margin-left: 18px;

  &:hover {
    svg {
      path {
        fill: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
      }
    }
  }

  svg {
    path {
      fill: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
    }
  }
`;

const ModelListElementOptions = ({
  model,
  onShare,
  onHide,
  onReveal,
  onDelete,
  showTacbrowse,
  showHide,
  showReveal,
  showShare,
  showDelete,
  disableDelete,
  history,
}: IModelListElementOptionsPropTypes) => {
  const { t } = useTranslation();

  const handleMenuAction =
    (action: (m: IModelListItem) => void) => (e: any) => {
      e.preventDefault();
      action(model);
    };

  const openOptions = (e: any) => {
    e.preventDefault();
  };

  const createSession = () => {
    history.replace(`${SESSION_ROUTES.sessionCreate}?modelId=${model.id}`);
  };

  if (!model?.id) {
    return null;
  }

  return (
    <div>
      <SkyeDropdown
        onChange={() => {}}
        overlay={
          <DropdownWrapper>
            {showShare && (
              <DropdownItem
                startIcon={<ShareIcon />}
                onClick={handleMenuAction(onShare)}
              >
                {t("share")}
              </DropdownItem>
            )}
            {showHide && (
              <DropdownItem
                startIcon={<PrivateIcon />}
                onClick={handleMenuAction(onHide)}
              >
                {t("hide")}
              </DropdownItem>
            )}
            {showReveal && (
              <DropdownItem
                startIcon={<PrivateIcon />}
                onClick={handleMenuAction(onReveal)}
              >
                {t("reveal")}
              </DropdownItem>
            )}
            {showDelete &&
              (disableDelete ? (
                <SkyeTooltip title={t("deleteModelDisabledTooltip")}>
                  <DropdownItem
                    disabled={true}
                    startIcon={<TrashIcon />}
                    onClick={handleMenuAction(onDelete)}
                  >
                    {t("delete")}
                  </DropdownItem>
                </SkyeTooltip>
              ) : (
                <DropdownItem
                  disabled={false}
                  startIcon={<TrashIcon />}
                  onClick={handleMenuAction(onDelete)}
                >
                  {t("delete")}
                </DropdownItem>
              ))}
            {showTacbrowse && (
              <DropdownItem
                startIcon={<TacbrowseIcon />}
                onClick={handleMenuAction(createSession)}
              >
                {t("createSession")}
              </DropdownItem>
            )}
          </DropdownWrapper>
        }
      >
        <OptionsIconButton onClick={openOptions}>
          <MoreIcon />
        </OptionsIconButton>
      </SkyeDropdown>
    </div>
  );
};

export default withRouter(ModelListElementOptions);
