import { useState } from "react";

export const useDialog = ({
  initialOpen = false,
}: { initialOpen?: boolean } = {}) => {
  const [open, setOpen] = useState(initialOpen);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return {
    open,
    openDialog,
    closeDialog,
  };
};
