import React, { useEffect, useState } from "react";
import {
  ANGLE_TOOL,
  AREA_TOOL,
  SLOPE_TOOL,
  FIELD_OF_VIEW_TOOL,
  DELETE_ALL_TOOL,
  DISTANCE_TOOL,
  HEIGHT_TOOL,
  LOCATION_POINT_TOOL,
  MEASURING_TOOLS,
  OPEN_PROFILE_PANEL,
  SWITCH_MEASUREMENT_UNIT_TOOL,
  SWITCH_TOPOGRAPHY_MAP,
  RESCALE_TOOL,
  PERSPECTIVE_TOOL,
  ANNOTATION_TOOL,
  VOLUME_CALCULATION_TOOL,
} from "../const";
import ToolIconSection from "./ToolIconSection";
import ViewerToolIcon from "./ViewerToolIcon";
import { ViewerService } from "../services/ViewerService";
import {
  AnalyticEventCategory,
  AnalyticService,
} from "services/AnalyticService";
import { useTranslation } from "react-i18next";
import { IViewerStore, TexturesMode } from "stores/ViewerStore";
import { inject, observer } from "mobx-react";
import { STORE_NAMES } from "const/global";
import { IGlobalStore } from "../../../stores/GlobalStore";
import { ModelAccessPolicy } from "../../models/ModelAccessPolicy";
import { IPublicModel, ModelPermissionsType } from "../../../models/Model";
import Distance from "../services/tools/Distance";
import Area from "../services/tools/Area";
import Angle from "../services/tools/Angle";
import BasementAngle from "../services/tools/BasementAngle";
import LocationPoint from "../services/tools/LocationPoint";
import Height from "../services/tools/Height";
import BaseMeasure from "../services/tools/BaseMeasure";
import useChangePerspectiveModelTool from "../hook/useChangePerspectiveModelTool";
import useTextureManager from "../../models/containers/ModelViewer/hooks/useTextureManager";
import useRescaleModelTool from "../hook/useRescaleModelTool";
import { useAnnotationTool } from "../hook/useAnnotationTool";
import { useMeasuringTools } from "../context/ViewerMeasuringToolsContext";
import ToolAvailabilityHoc from "../hoc/ToolAvailabilityHoc";
import useTopographyMap from "../hook/useTopographyMap";
import Volume from "../services/tools/VolumeTool";
import useCurrentUser from "../../account/hooks/useCurrentUser";

interface IViewerMeasurementsPanelPropTypes {
  ViewerStore?: IViewerStore;
  GlobalStore?: IGlobalStore;
  model: IPublicModel;
}

const viewerService = new ViewerService();

const ViewerMeasurementsPanel = ({
  ViewerStore,
  GlobalStore,
  model,
}: IViewerMeasurementsPanelPropTypes) => {
  const topographyMap = useTopographyMap({ ViewerStore });
  const toolsCtx = useMeasuringTools();

  const { user } = useCurrentUser();
  const [annotationToolDialog, initAnnotationTool] = useAnnotationTool();
  const { initRescaleTool, rescaleModal } = useRescaleModelTool();
  const [activeMeasureId, setActiveMeasureId] = useState(null);
  const { t } = useTranslation();
  const perspective = useChangePerspectiveModelTool({
    viewerInitialized: ViewerStore && ViewerStore.viewerInitialized,
    modelId: model.id,
  });
  const [texture] = useTextureManager({ ViewerStore });

  const onProfileCanceled = () => {
    viewerService.handleProfileToolCancel();
  };

  const onToolCanceled = () => {
    setActiveMeasureId(null);
    // Added cleanup for Volume tool if active
    if (activeMeasureId === VOLUME_CALCULATION_TOOL.id) {
      window.viewer.volumeTool.stopInsertion();
    }
  };

  const deleteProfileTool = () => {
    viewerService.closeProfileWindow();
  };

  const onProfilePanelClosed = () => {
    if (ViewerStore && ViewerStore.lastTexturesMode === TexturesMode.TEXTURES) {
      viewerService.setLowPointBudget();
      viewerService.disablePointCloudVisibility();
      texture.showTextures();
    }
  };

  useEffect(() => {
    if (ViewerStore && ViewerStore.texturesLoaded) {
      viewerService.setLowPointBudget();
      deleteProfileTool();
    }
  }, [ViewerStore && ViewerStore.texturesLoaded]);

  useEffect(() => {
    setTimeout(() => {
      window.viewer.scene.addEventListener(
        "profile_cancel_insertion",
        onProfileCanceled
      );
      window.viewer.scene.addEventListener(
        "tool_edit_canceled",
        onToolCanceled
      );
      window.viewer.scene.addEventListener(
        "cancel_perspective_tool",
        cancelPerspectiveTool
      );

      // Added listener for volume_measurement_completed event
      window.viewer.addEventListener(
        "volume_measurement_completed",
        onToolCanceled
      );

      document
        .querySelector("#closeProfileContainer")
        ?.addEventListener("click", onProfilePanelClosed);
    }, 1000);

    return () => {
      window.viewer.scene.removeEventListener(
        "profile_cancel_insertion",
        onProfileCanceled
      );
      window.viewer.scene.removeEventListener(
        "tool_edit_canceled",
        onToolCanceled
      );
      window.viewer.scene.removeEventListener(
        "cancel_perspective_tool",
        cancelPerspectiveTool
      );

      // Removed listener for volume_measurement_completed event
      window.viewer.removeEventListener(
        "volume_measurement_completed",
        onToolCanceled
      );

      document
        .querySelector("#closeProfileContainer")
        ?.removeEventListener("click", onProfilePanelClosed);
    };
  }, []);

  const openPerspectiveTool = () => {
    perspective.init();
  };

  const cancelPerspectiveTool = () => {
    // To implement in future if necessary
  };

  const ToolIconHoc = ToolAvailabilityHoc(ViewerToolIcon);

  const handleMeasureClick = (id: string) => {
    const modelAccess = ModelAccessPolicy.modelPermissionsType(
      user,
      model.accessType,
      model.accessExpiresAt
    );
    if (
      ![ModelPermissionsType.Premium, ModelPermissionsType.Lite].includes(
        modelAccess
      )
    ) {
      return;
    }

    setActiveMeasureId(id);
    AnalyticService.event(
      AnalyticEventCategory.ViewerMeasurements,
      `measurement_${id}`
    );

    const disablePointcloudVisibilityIfTextureMode = () => {
      if (
        ViewerStore &&
        ViewerStore.lastTexturesMode === TexturesMode.TEXTURES
      ) {
        viewerService.disablePointCloudVisibility();
      }
    };

    switch (id) {
      case ANNOTATION_TOOL.id:
        viewerService.setMediumPointBudget();
        disablePointcloudVisibilityIfTextureMode();
        initAnnotationTool();
        break;

      case DISTANCE_TOOL.id:
        viewerService.setMediumPointBudget();
        disablePointcloudVisibilityIfTextureMode();
        Distance.add();
        break;

      case AREA_TOOL.id:
        viewerService.setMediumPointBudget();
        disablePointcloudVisibilityIfTextureMode();
        Area.add();
        break;

      case ANGLE_TOOL.id:
        viewerService.setMediumPointBudget();
        disablePointcloudVisibilityIfTextureMode();
        Angle.add();
        break;

      case SLOPE_TOOL.id:
        viewerService.setMediumPointBudget();
        disablePointcloudVisibilityIfTextureMode();
        BasementAngle.add();
        break;

      case LOCATION_POINT_TOOL.id:
        LocationPoint.add();
        viewerService.setMediumPointBudget();
        disablePointcloudVisibilityIfTextureMode();
        break;

      case HEIGHT_TOOL.id:
        viewerService.setMediumPointBudget();
        disablePointcloudVisibilityIfTextureMode();
        Height.add();
        break;

      case SWITCH_MEASUREMENT_UNIT_TOOL.id:
        viewerService.setMediumPointBudget();
        viewerService.switchMeasurementUnit();
        break;

      case DELETE_ALL_TOOL.id:
        BaseMeasure.deleteAll();
        break;

      case SWITCH_TOPOGRAPHY_MAP.id:
        topographyMap.toggle();
        break;

      case OPEN_PROFILE_PANEL.id:
        viewerService.setMediumPointBudget();
        viewerService.enablePointCloudVisibility();
        texture.hideTextures();
        viewerService.openProfile();
        break;

      case FIELD_OF_VIEW_TOOL.id:
        viewerService.setMediumPointBudget();
        viewerService.changePerspectiveToPoint();
        break;

      case RESCALE_TOOL.id:
        viewerService.setMediumPointBudget();
        initRescaleTool();
        break;

      case PERSPECTIVE_TOOL.id:
        viewerService.setMediumPointBudget();
        openPerspectiveTool();
        break;

      case VOLUME_CALCULATION_TOOL.id:
        viewerService.setMediumPointBudget(); // Adjust point budget as needed
        disablePointcloudVisibilityIfTextureMode(); // Handle point cloud visibility
        Volume.add(); // Starts the volume insertion process
        break;

      default:
        return;
    }
  };

  return (
    <div id="viewer-measurements-panel">
      {rescaleModal}
      {annotationToolDialog}
      <ToolIconSection
        titleProps={{ title: t("tools") }}
        iconsSection={
          <>
            {MEASURING_TOOLS.map((measuringTool) => (
              <ToolIconHoc
                key={measuringTool.id}
                id={measuringTool.id}
                onClick={handleMeasureClick}
                active={activeMeasureId === measuringTool.id}
                tooltip={measuringTool.tooltip}
                available={
                  !toolsCtx.isToolBlocked(measuringTool.id) &&
                  ViewerStore &&
                  ViewerStore.viewerInitialized
                }
                notAvailableMessage={t(
                  "subscription.featureNotAvailableForCurrentPlan"
                )}
              />
            ))}
          </>
        }
      />
    </div>
  );
};

export default inject(
  STORE_NAMES.ViewerStore,
  STORE_NAMES.GlobalStore
)(observer(ViewerMeasurementsPanel));
