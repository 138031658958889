import React, { ReactNode } from 'react'
import styled from 'styled-components';
import { IAppContext } from "../../../interfaces";
import classNames from 'classnames';
import Box from '../Box';

interface IDropdownItemPropTypes {
  active?: boolean;
  children: ReactNode;
  value?: string | number;
  startIcon?: any;
  disabled?: boolean;
  onClick?: (e: any) => void;
}

const Wrapper = styled(Box)`
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 40px;
  padding: 0 ${(p: IAppContext) => p.theme.skye.sizes.medium};
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};

  &:hover {
    background-color: ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};
  }

  &.active {
    color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
  }

  &.disabled {
    color: ${(p: IAppContext) => p.theme.skye.colors.textDisabled};
    cursor: not-allowed;
  }
`;

const StartIconWrapper = styled(Box)`
  width: 18px;
  margin-right: ${(p: IAppContext) => p.theme.skye.sizes.small};
`;

const DropdownItem = ({
  active,
  children,
  startIcon,
  disabled,
  onClick,
  ...otherProps
}: IDropdownItemPropTypes) => {
  return (
    <Wrapper
      className={classNames(["dropdown-item", { active, disabled }])}
      {...otherProps}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        onClick?.(e);
      }}
    >
      {Boolean(startIcon) && <StartIconWrapper>{startIcon}</StartIconWrapper>}
      {children}
    </Wrapper>
  );
};

export default DropdownItem;
