import React, { useState } from "react";
import { IAppContext } from "views/interfaces";
import styled from "styled-components";
import { Row, Col } from "styled-bootstrap-grid";
import InputGroup from "views/shared/form/InputGroup";
import Box, { BoxAlignedCenter } from "views/shared/components/Box";
import Button from "views/shared/buttons/Button";
import { useTranslation } from "react-i18next";
import { MessageService } from "services/MessageService";
import UserAvatar from "views/shared/components/UserAvatar";
import { LoggedUser } from "models/User";
import { UserService } from "services/UserService";
import breakpoint from "styled-components-breakpoint";
import { useHistory } from "react-router";
import { USER_ROUTES } from "views/routes";
import useCurrentUser from "../../../../account/hooks/useCurrentUser";

interface IUserAccountSettingsContainerPropTypes {}

const AvatarColumn = styled(Box)`
  flex-direction: column;
  min-width: 260px;
  align-items: center;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.xLarge};

  ${breakpoint("md")`
    margin-bottom: 0;
  `}
`;
const UserDetailsColumn = styled.div`
  width: 100%;
`;
const AccountSettingsContent = styled(Box)`
  align-items: flex-start;
  flex-direction: column;

  .input-group {
    margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.large};
  }

  ${breakpoint("md")`
    flex-direction: row;
  `}
`;
const ButtonRow = styled(Box)`
  justify-content: space-between;
  width: 100%;

  .btn {
    margin-right: ${(p: IAppContext) => p.theme.skye.sizes.small};
  }
`;
const UserName = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0;
`;
const UserAvatarWrapper = styled(Box)`
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;

const getInitialValues = (user: LoggedUser) => {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone,
    jobTitle: user.jobTitle,
  };
};

const UserAccountSettingsContainer =
  ({}: IUserAccountSettingsContainerPropTypes) => {
    const history = useHistory();
    const { user } = useCurrentUser();
    const [inputValues, setInputValues] = useState(getInitialValues(user));
    const { t } = useTranslation();
    const userName = `${user.firstName} ${user.lastName}`;

    const setValue = (fieldName: string) => (e: any) => {
      if (typeof inputValues[fieldName] !== "undefined") {
        setInputValues({
          ...inputValues,
          [fieldName]: e.target.value,
        });
      }
    };

    const getValue = (fieldName: string) => {
      return inputValues[fieldName] || "";
    };

    const updateUser = () => {
      const newValues = {
        phone: getValue("phone"),
        jobTitle: getValue("jobTitle"),
        firstName: getValue("firstName"),
        lastName: getValue("lastName"),
      };
      UserService.updateLoggedUser(newValues)
        .then(() => {
          user.phone = newValues.phone;
          user.jobTitle = newValues.jobTitle;
          user.firstName = newValues.firstName;
          user.lastName = newValues.lastName;
          MessageService.success(t("profileUpdated"));
        })
        .catch(() => {
          MessageService.error(t("requestFailed"));
        });
    };

    const handleCancel = () => {
      setInputValues(getInitialValues(user));
    };

    const handleDelete = () => {
      history.push(USER_ROUTES.delete);
    };

    return (
      <AccountSettingsContent>
        <AvatarColumn>
          <UserAvatarWrapper>
            <UserAvatar userName={userName} size={112} />
          </UserAvatarWrapper>
          <UserName>{userName}</UserName>
        </AvatarColumn>
        <UserDetailsColumn>
          <Row>
            <Col sm={6}>
              <InputGroup
                bordered
                label="firstNameLabel"
                inputProps={{
                  value: getValue("firstName"),
                  placeholder: "firstNamePlaceholder",
                  onChange: setValue("firstName"),
                }}
              ></InputGroup>
            </Col>
            <Col sm={6}>
              <InputGroup
                bordered
                label="lastnameLabel"
                inputProps={{
                  value: getValue("lastName"),
                  placeholder: "lastnamePlaceholder",
                  onChange: setValue("lastName"),
                }}
              ></InputGroup>
            </Col>
            <Col sm={6}>
              <InputGroup
                bordered
                filled
                disabled
                label="emailLabel"
                inputProps={{
                  value: user.email,
                  placeholder: "emailPlaceholder",
                }}
              ></InputGroup>
            </Col>
            <Col sm={6}>
              <InputGroup
                bordered
                disabled
                filled
                label="organizationLabel"
                inputProps={{
                  value: user.organization.name,
                  placeholder: "organizationPlaceholder",
                }}
              ></InputGroup>
            </Col>
            <Col sm={6}>
              <InputGroup
                bordered
                label="phoneLabel"
                inputProps={{
                  value: getValue("phone"),
                  placeholder: "phonePlaceholder",
                  onChange: setValue("phone"),
                }}
              ></InputGroup>
            </Col>
            <Col sm={6}>
              <InputGroup
                bordered
                label="jobTitleLabel"
                inputProps={{
                  value: getValue("jobTitle"),
                  placeholder: "jobTitlePlaceholder",
                  onChange: setValue("jobTitle"),
                }}
              ></InputGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <ButtonRow>
                <BoxAlignedCenter>
                  <Button onClick={updateUser}>{t("save")}</Button>
                  <Button type="transparent" onClick={handleCancel}>
                    {t("cancel")}
                  </Button>
                </BoxAlignedCenter>
                <BoxAlignedCenter>
                  <Button
                    type="transparent"
                    color="danger"
                    onClick={handleDelete}
                  >
                    {t("delete")}
                  </Button>
                </BoxAlignedCenter>
              </ButtonRow>
            </Col>
          </Row>
        </UserDetailsColumn>
      </AccountSettingsContent>
    );
  };

export default UserAccountSettingsContainer;
