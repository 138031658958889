import httpService from "./HttpService";
import API from "../const/api";
import { IOpenUserRole, LoggedUser } from "models/User";
import { Organization, OrganizationUpdate } from "models/Organization";

export class OrganizationService {
  static async updateOrganization(organization: OrganizationUpdate) {
    const url = API.updateCurrentOrganization;
    return httpService.put(url, organization);
  }

  static async getUsers(): Promise<LoggedUser[]> {
    const url = API.organizationUsers;
    try {
      const response = await httpService.get(url);
      const users = response.data;
      return users.map((user) => ({
        ...user,
        id: String(user.id),
      }));
    } catch (error) {
      return [];
    }
  }

  static async getUser(id: number | string): Promise<LoggedUser | null> {
    try {
      if (!id) return null; //fixme: kamilsz - tu leci undefined czasem
      const url = `${API.organizationUsers}/${id}`;
      const user: any = (await httpService.get(url)).data;
      return {
        ...user,
        id: String(user.id),
      };
    } catch (error) {
      return null;
    }
  }

  static async assignRoles(email: string, roles: IOpenUserRole[]) {
    const url = `${API.organizationUsers}/${email}/roles`;
    return httpService.put(url, roles);
  }

  static inviteUser(
    email: string,
    firstName: string,
    lastName: string,
    organizationMaintainer?: boolean
  ) {
    const url = API.organizationUsers;
    const body = { email, firstName, lastName, organizationMaintainer };
    return httpService.post(url, body);
  }

  static async getCurrentOrganization(): Promise<Organization | null> {
    const url = API.getCurrentOrganization;
    try {
      const response = httpService.get(url);
      return (await response).data as Organization;
    } catch (error) {
      return null;
    }
  }

  static async removeUser(id: number) {
    const url = `${API.organizationUsers}/${id}`;
    return httpService.delete(url);
  }
}
