import Button from "../../../../../shared/buttons/Button";
import DiamondIcon from "../../../../../shared/components/AdvancedInformationRow/DiamondIcon";
import SubscriptionTypeBadge from "../../../../../shared/components/badges/SubscriptionTypeBadge/SubscriptionTypeBadge";
import SkyeSwitch from "../../../../../shared/form/SkyeSwitch";
import { SubscriptionPeriod } from "../../../../payments.model";
import PaymentOption from "../../../PaymentOption/PaymentOption";
import {
  LITE_ANNUAL_PRICE,
  LITE_MONTHLY_PRICE,
} from "../../../Pricing/pricing.const";

interface SubscriptionPaymentOptionProps {
  subscriptionPeriod: SubscriptionPeriod;
  onPeriodChange: (period: SubscriptionPeriod) => void;
  onBuy: (subscriptionPeriod: SubscriptionPeriod) => void;
}

const LiteSubscriptionPaymentOption = ({
  subscriptionPeriod,
  onBuy,
  onPeriodChange,
}: SubscriptionPaymentOptionProps) => {
  return (
    <PaymentOption
      featured
      featuredLabel="Most popular!"
      priceLabel={
        <div>
          <div>
            <h6 className="font-medium text-2xl mb-2">Lite</h6>
          </div>
          <div>
            <span className="text-3xl font-base">
              $
              {subscriptionPeriod === SubscriptionPeriod.Annually
                ? LITE_ANNUAL_PRICE
                : LITE_MONTHLY_PRICE}
            </span>
            <span className="text-xs text-gray-500 font-base">/ month</span>
          </div>
        </div>
      }
      switchPlacement={
        <div className="flex items-center justify-top mb-4">
          <div className="sub-period-label text-xs px-2">Monthly</div>
          <SkyeSwitch
            checked={subscriptionPeriod === SubscriptionPeriod.Annually}
            bottomPadding={false}
            onChange={() =>
              subscriptionPeriod === SubscriptionPeriod.Annually
                ? onPeriodChange(SubscriptionPeriod.Monthly)
                : onPeriodChange(SubscriptionPeriod.Annually)
            }
          />
          <div className="sub-period-label text-xs px-2 relative">
            Annual{" "}
            <div className="absolute top-5 left-2 min-w-[75px]">
              <div className="w-fit">
                <SubscriptionTypeBadge text="Save 33%" />
              </div>
            </div>
          </div>
        </div>
      }
      description="Get access to all of your models and standard tools! Great for surveys, roof reports, real estate, and more."
      icon={
        <div className="w-12 h-12 rounded-md bg-primary-100 flex justify-center items-center p-1">
          <DiamondIcon />
        </div>
      }
      buttonsPlacement={
        <div className="w-full block">
          <Button
            type="solid"
            color="primary"
            fullSize
            onClick={() => onBuy(subscriptionPeriod)}
          >
            Subscribe
          </Button>
        </div>
      }
    />
  );
};

export default LiteSubscriptionPaymentOption;
