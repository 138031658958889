import { inject, observer } from 'mobx-react';
import { LoggedUser } from "../../../models/User";
import React, { useEffect, useState } from 'react'
import { OrganizationService } from "../../../services/OrganizationService";
import { IGlobalStore } from "../../../stores/GlobalStore";
import UserChooseModal, { SelectableUser } from '../components/UserChooseModal';
import useCurrentUser from "../../account/hooks/useCurrentUser";

interface IUserChooseModalContainerPropTypes {
  open: boolean;
  GlobalStore?: IGlobalStore;
  currentSelectedUsers?: LoggedUser[];
  onClose: () => void;
  onInvite: (users: LoggedUser[]) => void;
}

const UserChooseModalContainer = ({
  open,
  currentSelectedUsers = [],
  onClose,
  onInvite,
}: IUserChooseModalContainerPropTypes) => {
  const { user } = useCurrentUser();
  const [users, setUsers] = useState<SelectableUser[]>([]);

  const getUsers = () => {
    OrganizationService.getUsers().then((users: any) => {
      setUsers(
        users
          .filter((u) => u.id !== user.id)
          .map((user) => {
            user.selected = Boolean(
              currentSelectedUsers.find((u) => u.id === user.id)
            );
            return user;
          })
      );
    });
  };

  const handleSelect = (user: LoggedUser) => {
    setUsers(
      users.map((u) => {
        if (user.id === u.id) {
          u.selected = true;
        }
        return u;
      })
    );
  };

  const handleDeselect = (user: LoggedUser) => {
    setUsers(
      users.map((u) => {
        if (user.id === u.id) {
          u.selected = false;
        }
        return u;
      })
    );
  };

  const handleClose = () => {
    onClose();
  };

  const handleInvite = () => {
    onInvite(users.filter((u) => u.selected));
  };

  useEffect(() => {
    if (open) {
      getUsers();
    }
  }, [open]);

  return (
    <UserChooseModal
      open={open}
      onClose={handleClose}
      users={users}
      onDeselect={handleDeselect}
      onSelect={handleSelect}
      onInvite={handleInvite}
    />
  );
};

export default inject('GlobalStore')(observer(UserChooseModalContainer));;
