import { useState } from "react";
import AuthService from "../../../../services/AuthService";
import { UserService } from "../../../../services/UserService";
import GlobalStore from "../../../../stores/GlobalStore";
import { MessageService } from "../../../../services/MessageService";
import authRequests from "../../../../requests/auth";
import { useTranslation } from "react-i18next";
import { getResponseErrorTranslation } from "../../../../utils/globalUtils";
import { WindowUtils } from "../../../../utils/windowUtils";
import { AUTH_ROUTES, MODEL_ROUTES } from "../../../routes";
import useCurrentUser from "../../../account/hooks/useCurrentUser";

interface State {
  email: string;
  password: string;
  isLoading: boolean;
  resetPasswordDialogOpen: boolean;
  resetPasswordRequestError: null | string;
  resetPasswordLoading: boolean;
}

const usePage = ({ history }) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const [state, setState] = useState<State>({
    email: "",
    password: "",
    isLoading: false,
    resetPasswordDialogOpen: false,
    resetPasswordRequestError: null,
    resetPasswordLoading: false,
  });

  const handleEmailRequest = () => {
    let { email } = state;
    email = email.trim();

    setState((old) => ({
      ...old,
      resetPasswordRequestError: null,
      resetPasswordLoading: true,
    }));

    authRequests
      .resetPasswordRequest(email)
      .then(() => {
        setState((old) => ({ ...old, resetPasswordDialogOpen: false }));
        MessageService.success(t("emailSent"));
      })
      .catch((err) => {
        setState((old) => ({
          ...old,
          resetPasswordRequestError: getResponseErrorTranslation(err),
        }));
      })
      .finally(() => {
        setState((old) => ({ ...old, resetPasswordLoading: false }));
      });
  };

  const moveToRegister = () => {
    history.push(AUTH_ROUTES.register);
  };

  const redirectAfterLogin = () => {
    const redirectRoute = WindowUtils.getRedirectUrl();

    if (redirectRoute) {
      history.push(redirectRoute);
      GlobalStore.setRedirectRoute("");
    } else {
      history.push(MODEL_ROUTES.list.replace(":page", String(1)));
    }
  };

  const login = async () => {
    setState((old) => ({ ...old, isLoading: true }));

    let { email, password } = state;
    email = email.trim().toLocaleLowerCase();
    AuthService.signOut();

    AuthService.signIn({ email, password })
      .then(async (response) => {
        currentUser.setUser(response.data.user);
        redirectAfterLogin();
      })
      .catch((err) => {
        MessageService.error(t(err.localizationKey || "requestFailed"));
        setState((old) => ({ ...old, isLoading: false }));
      });
  };

  const onEmailChange = (e: any) => {
    setState((old) => ({ ...old, email: e.target.value }));
  };

  const closeDialog = () => {
    setState((old) => ({ ...old, resetPasswordDialogOpen: false }));
  };

  return {
    state,
    setState,
    login,
    closeDialog,
    handleEmailRequest,
    onEmailChange,
    moveToRegister,
  };
};

export default usePage;
