import { useContext, useMemo } from "react";
import { UserContext } from "../context/user.context";
import { IOpenUserRole, LoggedUser, UserHelpers } from "../../../models/User";

const useCurrentUser = () => {
  const ctx = useContext(UserContext);
  if (!ctx) {
    throw new Error("useCurrentUser must be used within a UserContextProvider");
  }

  const { user } = ctx;

  const setUser = (user: LoggedUser) => {
    ctx.setUser(user);
  };

  const hasRole = (role: IOpenUserRole) => {
    return UserHelpers.hasRole(user, role);
  };

  const isOwner = useMemo(() => {
    if (!user || !user.organization) return false;
    return user.id === user.organization.ownerId;
  }, [user]);

  return { user: ctx.user, isOwner, setUser, hasRole };
};

export default useCurrentUser;
