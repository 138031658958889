import React from "react";
import ModelListElement from "./ModelListElement";
import { getModelSharedUrl } from "../../../../../../utils/globalUtils";
import { ModelPageViewTypes } from "../../../../../../const/global";
import { ISharedInModelItem } from "../../../../../../models/SharedModel";
import styled from "styled-components";
import { IAppContext } from "../../../../../interfaces";
import { useTranslation } from "react-i18next";
import { ModelAccessPolicy } from "../../../../ModelAccessPolicy";
import { inject, observer } from "mobx-react";
import { IGlobalStore } from "../../../../../../stores/GlobalStore";
import ModelBadge from "./components/ModelBadge/ModelBadge";
import useCurrentUser from "../../../../../account/hooks/useCurrentUser";

interface ISharedInModelListElementPropTypes {
  GlobalStore?: IGlobalStore;
  sharedInModel: ISharedInModelItem;
}

const ShareRow = styled.div`
  font-size: 0.9em;
  color: ${(p: IAppContext) => p.theme.skye.colors.textSecondary};
`;

const mockFn = () => {};

const SharedInModelListElement = (
  props: ISharedInModelListElementPropTypes
) => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const { sharedInModel } = props;
  const { model } = sharedInModel;

  const accessLocked = ModelAccessPolicy.isLocked(
    model.status,
    model.accessType
  );
  const accessType = ModelAccessPolicy.modelPermissionsType(
    user,
    model.accessType,
    model.accessExpiresAt
  );

  return (
    <ModelListElement
      accessType={accessType}
      accessLocked={accessLocked}
      badge={
        accessLocked && (
          <ModelBadge status={model.status} accessType={accessType} />
        )
      }
      showOptions={false}
      showShare={false}
      showDelete={false}
      showHide={false}
      showReveal={false}
      showTacbrowse={false}
      shareContent={
        <ShareRow className="share-by">
          <b>{t("sharedBy")}:</b> {sharedInModel.sharedBy.email}
        </ShareRow>
      }
      key={model.id}
      model={model}
      mapRedirectUrl={getModelSharedUrl(model.id, ModelPageViewTypes.Map)}
      modelRedirectUrl={getModelSharedUrl(model.id, ModelPageViewTypes.Model)}
      onShare={mockFn}
      onHide={mockFn}
      onReveal={mockFn}
      onDelete={mockFn}
      {...props}
    />
  );
};

export default inject("GlobalStore")(observer(SharedInModelListElement));
