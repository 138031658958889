import React, { useState, useEffect, useCallback } from "react";
import { IPublicModel, ModelStatus } from "models/Model";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ModelService from "views/models/services/ModelService";
import Heading from "views/shared/typography/Heading";
import ModelPageLayout from "../../layouts/ModelPageLayout/ModelPageLayout";
import {
  AnalyticService,
  AnalyticEventCategory,
  EventActionType,
} from "services/AnalyticService";
import { MessageService } from "services/MessageService";
import { Helmet } from "react-helmet";
import { ModelHelpers } from "models/Model";
import { WindowUtils } from "utils/windowUtils";
import {
  ModelPageViewTypes,
  MODEL_PAGE_VIEW_TYPE_PARAM_NAME,
  ModelDetailsPageTabKeys,
} from "const/global";
import PublicPageLayout from "views/shared/layouts/PublicPageLayout";
import GlobalStore from "stores/GlobalStore";
import { inject, observer } from "mobx-react";
import { getLogoUrl } from "../../../../utils/globalUtils";
import moment from "moment";
import { ModelAnalyticService } from "../../services/ModelAnalyticService";
import withViewerProviders from "../../../viewer/hoc/withViewerProviders";
import ModelMapView from "../../components/ModelMapView";
import SidebarComponent from "./Sidebar/Sidebar.component";
import ContentSectionComponent from "../components/ContentSection.component";
import ModelPublicDetailsPageViewer from "./Viewer/Viewer.component";
import PublicModelDetailsPageDownloadSectionComponent from "./DownloadSection/PublicModelDetailsPageDownloadSection.component";
import Dialog from "../../../shared/components/Dialog/Dialog";
import ModelOrthophotoViewer from "../components/ModelOrthophotoViewer";
import PublicModelPageHeader from "./Header";
import { CanUploadProvider } from "../../hooks/useCanUpload";
import useCurrentUser from "../../../account/hooks/useCurrentUser";
import { UserService } from "../../../../services/UserService";

interface IMatchParams {
  publicKey: string;
}

interface IPublicModelDetailsPagePropTypes
  extends RouteComponentProps<IMatchParams> {}

export const getOgImageUrl = (model: IPublicModel) => {
  return model && model.thumbnailCreated && model.assets.thumbnailsUrls
    ? model.assets.thumbnailsUrls[0]
    : getLogoUrl();
};

export const getOgDescription = (model?: IPublicModel) => {
  if (!model) {
    return "";
  }
  return `Model created at ${moment(model.created).format("LLL")}`;
};

const PublicModelDetailsPage = ({
  history,
  match,
}: IPublicModelDetailsPagePropTypes) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const [model, setModel] = useState<null | IPublicModel>(null);
  const [, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [tags] = useState<string[]>([]);
  const [currentTabKey, setCurrentTabKey] = useState(
    ModelDetailsPageTabKeys.General
  );
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [viewType, setViewType] = useState(
    WindowUtils.getQueryParam(
      MODEL_PAGE_VIEW_TYPE_PARAM_NAME,
      ModelPageViewTypes.Model
    )
  );
  const publicUrl = model ? ModelHelpers.getPublicUrl(model.publicKey) : "";
  const ogDescription = getOgDescription(model);
  const ogImage = getOgImageUrl(model);
  const ogTitle = `Skyebrowse model: ${model ? model.name : ""}`;

  const getUser = async () => {
    UserService.getLoggedUser().then((res) => {
      currentUser.setUser(res.data);
    });
  };

  const getPublicModel = useCallback(
    (publicKey: string): Promise<IPublicModel | null> => {
      setLoading(true);
      return ModelService.getPublicModel(publicKey)
        .then((res) => {
          ModelAnalyticService.modelOpened(
            model,
            viewType === ModelPageViewTypes.Model,
            AnalyticEventCategory.ModelDetailsPage
          );
          setModel(res.data);
          return res.data;
        })
        .catch(() => {
          AnalyticService.event(
            AnalyticEventCategory.PublicModelDetailsPage,
            "model_not_loaded",
            EventActionType.LoadError
          );
          MessageService.error(t("toastPublicModelNotAvailable"));
          return null;
        })
        .finally(() => {
          setLoading(false);
          setLoaded(true);
        });
    },
    []
  );

  useEffect(() => {
    const init = async () => {
      const { publicKey } = match.params;
      getUser();
      const model = await getPublicModel(publicKey);

      if (model.status !== ModelStatus.Finished) {
        setViewType(ModelPageViewTypes.Map);
      }
    };
    init();
  }, [history, match.params.publicKey, match.params]);

  /**
   * Refresh the model details every 10 seconds to keep data up to date
   */
  useEffect(() => {
    const interval = setInterval(() => {
      getPublicModel(match.params.publicKey);
    }, 10 * 1000);

    return () => clearInterval(interval);
  }, [getPublicModel, match.params.publicKey]);

  const renderErrorMessage = () => (
    <Heading>{t("toastPublicModelNotAvailable")}</Heading>
  );
  const downloadSection = (
    <PublicModelDetailsPageDownloadSectionComponent model={model} />
  );

  const renderContent = () => (
    <>
      <Dialog
        size="medium"
        open={downloadModalOpen}
        title={t("download")}
        onClose={() => setDownloadModalOpen(false)}
        hideCancelButton
        hideConfirmButton
      >
        {downloadSection}
      </Dialog>
      <ModelPageLayout
        orthophotoViewerComponent={
          <ModelOrthophotoViewer showDownload={false} model={model} />
        }
        headerRowComponent={
          <PublicModelPageHeader
            showDownloadButton
            onDownload={() => setDownloadModalOpen(true)}
            model={model}
            view={viewType}
            onViewChange={(viewType: ModelPageViewTypes) =>
              setViewType(viewType)
            }
          />
        }
        mapComponent={<ModelMapView model={model} points={[]} />}
        view={viewType}
        sidebarComponent={
          <SidebarComponent
            currentTabKey={currentTabKey}
            setCurrentTabKey={setCurrentTabKey}
          />
        }
        contentSectionComponent={
          <ContentSectionComponent
            downloadSectionComponent={downloadSection}
            editable={false}
            currentTabKey={currentTabKey}
            setCurrentTabKey={(key) =>
              setCurrentTabKey(key as ModelDetailsPageTabKeys)
            }
            model={model}
            onModelChange={() => {}}
          />
        }
        viewerComponent={<ModelPublicDetailsPageViewer model={model} />}
      />
    </>
  );

  return (
    <CanUploadProvider>
      <PublicPageLayout>
        {model && (
          <Helmet>
            <meta property="og:type" content="website" />
            <meta property="og:url" content={publicUrl} />
            <meta property="og:title" content={ogTitle} />
            <meta property="og:description" content={ogDescription} />
            <meta property="og:image" content={ogImage} />
          </Helmet>
        )}
        <>{loaded && (!!model ? renderContent() : renderErrorMessage())}</>
      </PublicPageLayout>
    </CanUploadProvider>
  );
};

export default inject("GlobalStore")(
  withRouter(observer(withViewerProviders(PublicModelDetailsPage)))
);
