import { inject, observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IAppContext, ModelType } from "views/interfaces";
import Box from "views/shared/components/Box";
import SkyeDropdown, {
  DropdownWrapper,
} from "views/shared/components/Dropdown/Dropdown";
import DropdownItem from "views/shared/components/Dropdown/DropdownItem";
import ArrowDownIcon from "views/shared/Icons/ArrowDownIcon";
import { IOpenUserRole, UserHelpers } from "../../../../models/User";
import { IGlobalStore } from "../../../../stores/GlobalStore";
import useCurrentUser from "../../../account/hooks/useCurrentUser";

interface IModelTypeDropdownPropTypes {
  GlobalStore?: IGlobalStore;
  currentModelType: ModelType;
  onChange: (modelType: ModelType) => void;
}

const MyModelsDropdownButton = styled(Box)`
  height: ${(p: IAppContext) => p.theme.skye.sizes.xLarge};
  border: ${(p: IAppContext) => p.theme.skye.borders.primary};
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
  min-width: 260px;
  align-items: center;
  justify-content: center;
  padding: 0 18px;
  cursor: pointer;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  justify-content: space-between;

  svg {
    width: 22px;
    margin-left: 8px;
  }
`;

const ORGANIZATION_DROPDOWN_ITEM = {
  type: ModelType.Organization,
  dictionary: "organization",
};

const MODEL_TYPE_DROPDOWN_ITEMS = [
  { type: ModelType.Owned, dictionary: "owned" },
  { type: ModelType.Shared, dictionary: "sharedToMe" },
  { type: ModelType.SharedToOthers, dictionary: "sharedToOthers" },
  { type: ModelType.Archived, dictionary: "hidden" },
];

const getModelTypeDictionary = (modelType: ModelType) => {
  switch (modelType) {
    case ModelType.Archived:
      return "hidden";
    case ModelType.Shared:
      return "sharedToMe";
    case ModelType.SharedToOthers:
      return "sharedToOthers";
    case ModelType.Organization:
      return "organization";
    case ModelType.Owned:
    default:
      return "owned";
  }
};

const ModelTypeDropdown = ({
  currentModelType,
  onChange,
}: IModelTypeDropdownPropTypes) => {
  const { t } = useTranslation();
  const { user, isOwner } = useCurrentUser();
  const showOrganization =
    UserHelpers.hasRole(user, IOpenUserRole.OrganizationMaintainer) || isOwner;

  const allowedItems = () => {
    const items = [...MODEL_TYPE_DROPDOWN_ITEMS];

    return showOrganization ? [...items, ORGANIZATION_DROPDOWN_ITEM] : items;
  };

  const renderDropdownItem = (modelType: ModelType) => (
    <DropdownItem
      key={modelType}
      active={modelType === currentModelType}
      onClick={() => {
        onChange(modelType);
      }}
    >
      {t(getModelTypeDictionary(modelType))}
    </DropdownItem>
  );

  return (
    <SkyeDropdown
      trigger={["click"]}
      overlay={
        <DropdownWrapper>
          {allowedItems().map((item) => renderDropdownItem(item.type))}
        </DropdownWrapper>
      }
      onChange={() => {}}
    >
      <MyModelsDropdownButton>
        <div>{t(getModelTypeDictionary(currentModelType))}</div>
        <ArrowDownIcon />
      </MyModelsDropdownButton>
    </SkyeDropdown>
  );
};

export default inject("GlobalStore")(observer(ModelTypeDropdown));
