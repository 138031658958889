import React, { useMemo } from "react";
import DeleteModelDialog, { useDeleteModelDialog } from "./DeleteDialog";
import { ModelAccessPolicy } from "../ModelAccessPolicy";
import useCurrentUser from "../../account/hooks/useCurrentUser";
import Button from "../../shared/buttons/Button";
import TrashIcon from "../../shared/Icons/TrashIcon";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { MODEL_ROUTES } from "../../routes";
import { useCanUploadContext } from "../hooks/useCanUpload";
import { ModelStatus } from "../../../models/Model";
import SkyeTooltip from "../../shared/components/SkyeTooltip";
import { useTranslation } from "react-i18next";

interface ModelDeleteButtonProps extends RouteComponentProps {
  model: {
    id: string;
    name: string;
    ownerId: number;
    status: ModelStatus;
  };
}

const ModelDeleteButton = ({ model, history }: ModelDeleteButtonProps) => {
  const canUploadContext = useCanUploadContext();
  const dialog = useDeleteModelDialog();
  const { user } = useCurrentUser();
  const { t } = useTranslation();

  const onDeleted = async () => {
    await canUploadContext.fetch();
    dialog.close();
    history.push(MODEL_ROUTES.list);
  };

  const deleteDisabled = ModelAccessPolicy.isDeleteDisabled(model);
  const deleteButton = useMemo(() => {
    return (
      <Button
        disabled={deleteDisabled}
        id="model-share-button"
        className="share-btn right-row-btn"
        endIcon={<TrashIcon />}
        type="light"
        onClick={() => dialog.open(model)}
      ></Button>
    );
  }, []);

  if (!ModelAccessPolicy.canDelete(user, user.id === model.ownerId)) {
    return null;
  }

  return (
    <div className="model-delete-button">
      <DeleteModelDialog
        open={dialog.isOpen}
        onClose={dialog.close}
        onDeleted={onDeleted}
        model={model}
      />
      {deleteDisabled ? (
        <SkyeTooltip title={t("deleteModelDisabledTooltip")}>
          {deleteButton}
        </SkyeTooltip>
      ) : (
        deleteButton
      )}
    </div>
  );
};

export default withRouter(ModelDeleteButton);
