import { forwardRef, useImperativeHandle, useState } from "react";
import Dialog from "../../../../shared/components/Dialog/Dialog";
import { OrganizationService } from "../../../../../services/OrganizationService";

interface DeleteUser {
  id: number;
  name: string;
}

export interface DeleteUserDialogRef {
  open: (user: DeleteUser) => void;
  onDeleted: () => void;
}

const DeleteUserDialog = forwardRef(
  ({ onDeleted }: { onDeleted: () => void }, ref) => {
    const [user, setUser] = useState<DeleteUser | null>(null);
    const [isOpen, setOpen] = useState(false);

    const onClose = () => {
      setOpen(false);
    };

    const handleDeleteClick = () => {
      setOpen(false);
      OrganizationService.removeUser(user?.id).then(() => {
        onDeleted();
      });
    };

    const dialogRef = useImperativeHandle(
      ref,
      () => ({
        open: (user: DeleteUser) => {
          setUser(user);
          setOpen(true);
        },
      }),
      []
    );

    return (
      <Dialog
        title="Delete User"
        description={`Are you sure you want to delete ${user?.name}?`}
        open={isOpen}
        onClose={onClose}
        confirmButtonProps={{
          onClick: handleDeleteClick,
        }}
        cancelButtonProps={{
          onClick: onClose,
        }}
      ></Dialog>
    );
  }
);

DeleteUserDialog.displayName = "DeleteUserDialog";

export default DeleteUserDialog;
